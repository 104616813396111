<template>
	<div class="search-container">
		<div class="flex-row-center">
			<Input
				search
				style="width:500px;"
				:enter-button="'搜索' | language"
				:placeholder="'请输入搜索内容' | language"
				size="large"
				@on-change="show = false"
				v-model="keyword"
				@on-search="fetchData"
				@on-enter="fetchData"
			/>
		</div>
		<div class="search-content mr-t2 mr-b3" v-if="show">
			{{ '关于' | language }}{{ keyword }}{{ '的搜索结果共有' | language }}{{ total }}条
		</div>
		<div class="search-list" v-if="show && list.length">
			<div class="item" v-for="item in list" :key="item._id">
				<div class="time">{{ obj[item.source].text | language }}</div>

				<div class="title click-op" @click="goTo(item)">{{ item.title }}</div>
			</div>
		</div>
		<Page
			v-if="show && list.length"
			:total="total"
			:current="pageNum"
			:page-size="pageSize"
			@on-change="handlePageChange"
			style="margin-top:40px;margin-bottom:40px;"
		></Page>
	</div>
</template>

<script>
export default {
	data() {
		return {
			keyword: '',
			pageSize: 10,
			pageNum: 1,
			total: 0,
			list: [],
			show: false,
			obj: {
				NewsModel: { text: '新闻', url: '/base/news/detail' },
				ETalkModel: { text: 'E-Talk', url: '/base/eTalk/detail' },
				CourseModel: { text: '特色课程', url: '/base/courses/detail' },
				CompetitionModel: {
					text: '活动竞赛',
					url: '/base/information/competition-activities/detail',
				},
				ResearchModel: { text: '科创课题', url: '/base/info/project/detail' },
			},
		};
	},
	methods: {
		goTo(item) {
			this.$router.push(`${this.obj[item.source].url}/${item.infoId}`);
		},
		//分页
		handlePageChange(page) {
			this.pageNum = page;
			this.fetchData();
		},
		async fetchData() {
			this.$Spin.show();
			this.show = false;
			this.list = [];
			let res = await this.$http.get(
				`/home/search?keyword=${this.keyword}&pageSize=${this.pageSize}&pageNum=${this
					.pageNum - 1}`
			);
			this.list = res.result;
			this.total = res.total;
			this.show = true;
			this.$Spin.hide();
		},
	},
};
</script>

<style lang="less" scoped>
.search-container {
	width: 100%;
	padding-bottom: 20px;
	.search-content {
		font-size: 16px;
		font-weight: 600;
		text-align: left;
	}
	.search-list {
		padding-top: 20px;
		border-top: 1px solid #d5d7da;
		.item {
			display: flex;
			align-items: center;
			padding-left: 10px;
			padding-bottom: 20px;
			margin-bottom: 20px;
			border-bottom: 1px solid #d5d7da;

			.time {
				width: 200px;
				background-color: #0c2433;
				padding: 5px 10px;

				font-size: 16px;
				font-weight: 600;
				color: #fff;
				line-height: 22px;
			}

			.title {
				margin-left: 140px;
				height: 29px;
				font-size: 16px;
				font-weight: 600;
				color: rgba(0, 0, 0, 0.9);

				opacity: 0.9;

				line-height: 29px;
			}
			//   &:last-child {
			//     border-bottom: none;
			//   }
		}
	}
}
</style>
